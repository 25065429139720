<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dark :style="stickyTop" extension-height="4">
			<v-toolbar-title>{{ $t("Diversen dagboek") }} </v-toolbar-title>
			<v-autocomplete style="width: auto; max-width: 225px" class="text-overline kmp" v-model="fiscalPeriod"
				:items="sessionStore.fiscalPeriods" placeholder="filter op periode" hide-details solo flat dense outlined
				clearable append-icon="mdi-filter-outline" @change="refreshGeneralJournal"></v-autocomplete>
			<template #extension v-if="loading">
				<v-progress-linear bottom indeterminate color="white"></v-progress-linear>
			</template>
		</v-toolbar>
		<v-container>
			<v-row v-for="entry of generalJournal" :key="entry.stuknr">
				<v-col cols="12">
					<v-card min-width="600" max-width="960" class="mx-auto">
						<v-card-title class="text-subtitle-2">
							<v-row align="center">
								<v-col cols="1">
									{{ entry.stuknr }}
								</v-col>
								<v-col>
									{{ entry.opmerking }}
								</v-col>
							</v-row>
						</v-card-title>
						<v-divider />
						<v-card-text class="grey lighten-5">
							<v-row>
								<v-col cols="12">
									<v-sheet outlined>
										<v-data-table disable-sort disable-pagination hide-default-footer
											:items="entry.booking" :headers="bookingHeaders" dense>
											<template v-slot:[`item.omschrijving`]="{
												item,
											}">
												{{
													item.naam
													? item.naam
														.substring(0, 40)
														.toLowerCase()
													: item.omschrijving
														.substring(0, 40)
														.toLowerCase()
												}}
											</template>
											<template v-slot:[`item.debet`]="{ item }">
												<span v-if="item.debet">{{
													$n(item.debet, "ccy")
												}}</span>
											</template>
											<template v-slot:[`item.credit`]="{ item }">
												<span v-if="item.credit">{{
													$n(item.credit, "ccy")
												}}</span>
											</template>
											<template v-slot:[`item.ref`]="{ item }">
												{{
													item.ref
														.substring(0, 45)
														.toLowerCase()
												}}
											</template>
										</v-data-table>
									</v-sheet>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col></v-row>
			<v-row>
				<v-spacer />
				<v-btn text outlined class="my-3" v-if="this.rowIdent" color="primary" :loading="loading"
					@click="fetchgeneralJournal()">
					meer tonen
				</v-btn>
				<v-spacer />
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import { useSessionStore } from "@/store/session";
export default {
	name: "GeneralJournal",
	computed: {
		bookingHeaders() {
			return [
				{ text: "Periode", value: "periode", width: "75px" },
				{ text: "Reknr", value: "gnr", width: "75px" },
				{
					text: "Omschrijving",
					value: "omschrijving",
					width: "250",
				},
				{ text: "Debet", value: "debet", align: "end", width: "125" },
				{ text: "Credit", value: "credit", align: "end", width: "125" },
				{ text: "Ref", value: "ref" },
			];
		},
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 5,
			};
		},
	},
	data() {
		return {
			fiscalPeriod: 0,
			loading: false,
			generalJournal: [],
			rowNum: 0,
			rowIdent: "",
			rowsToBatch: 25,
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async fetchGeneralJournal() {
			this.loading = true;
			let params = {
				year: Number(this.sessionStore.fiscalYear),
				period: Number(this.fiscalPeriod) || 0,
				rowNum: this.rowNum,
				rowIdent: this.rowIdent,
				rowsToBatch: this.rowsToBatch,
			};
			const response = await caas.rpc("getGeneralJournal", params);
			if (
				response.data &&
				response.data.data.generalJournal.hasOwnProperty(
					"entry"
				)
			) {
				this.generalJournal.push(
					...response.data.data.generalJournal.entry
				);

				this.rowNum = response.data.rowNum;
				this.rowIdent = response.data.rowIdent;
			} else if (response.data.error) {
				console.log(response.error);
			}
			this.loading = false;
		},
		refreshGeneralJournal() {
			this.generalJournal = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.fetchGeneralJournal();
		},
	},
	mounted() {
		this.fetchGeneralJournal();
	},
};
</script>

<style>
</style>